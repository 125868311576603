// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Components
import {PageLoadingIndicator} from 'modules/App/components';
import {CrewPrepareDocumentSignaturesList} from 'modules/Job/Crew/Document/Show/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
`;

const Header = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const DocumentSignatures = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const STEP = 'DURING_MOVE';

const PrepareDuringMoveShowDocumentCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({
      uuid: params.uuid,
      documentUuid: params.documentUuid,
      step: STEP,
    })}
    query={PrepareDuringMoveShowDocumentCrewJobPage.query}
    onFocus={({refetch}: any) => refetch()}
  >
    {({navigator, params, loading, data, refetch}: any) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <CrewJobProgress selected={'documents'} items={CrewJobProgress.items.start} />
            <ScrollView style={{flex: 1}}>
              <Content>
                <Header>
                  <Title>{Document.getDisplayNameWithIndex(data.document)}</Title>
                  <Subtitle>Prepare this document by filling out the sections below.</Subtitle>
                </Header>
                <DocumentSignatures>
                  <CrewPrepareDocumentSignaturesList
                    documentSignatures={data.document.filteredDocumentSignatures}
                    document={data.document}
                    refetch={refetch}
                  />
                </DocumentSignatures>
              </Content>
            </ScrollView>
            <Footer>
              <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                <Text>Back</Text>
              </Button>
              <Button
                disabled={!data.document.isStepPrepared}
                color={colors.blue.interactive}
                onPress={() => {
                  navigator.navigate('SignDocumentCustomerJob', {
                    uuid: params.uuid,
                    documentUuid: params.documentUuid,
                    step: Document.formatStepAsParam(STEP),
                    success: params.success,
                  });
                }}
              >
                <Text>Submit</Text>
              </Button>
            </Footer>
          </Container>
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareDuringMoveShowDocumentCrewJobPage.query = gql`
  ${Document.getDisplayNameWithIndex.fragment}
  ${CrewJobPage.fragment}
  ${CrewPrepareDocumentSignaturesList.fragment}

  query PrepareDuringMoveShowDocumentCrewJobPage(
    $uuid: String!,
    $documentUuid: String!,
    $step: String!,
  ) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
      indexForStep(step: $step)
      isStepPrepared(step: $step)
      filteredDocumentSignatures(step: $step) {
        id
        ...CrewPrepareDocumentSignaturesList
      }
      ...Document_getDisplayNameWithIndex
      ...CrewPrepareDocumentSignaturesList_Document
    }
    job(uuid: $uuid) {
      id
      ...CrewJobPage
    }
  }
`;

export default PrepareDuringMoveShowDocumentCrewJobPage;
